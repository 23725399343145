<template>
  <div class="list-reward-list">
    <el-table v-bind:data="propData">
      <el-table-column label="Name" width="300px">
        <template slot-scope="{row}">
          <span>{{ row.name }}</span>
        </template>
      </el-table-column>
      <el-table-column label="Value" width="100px">
        <template slot-scope="{row}">
          <template v-if="propEditMode">
            <el-input placeholder="Please input value" v-model="row.value" @change="handleChange"></el-input>
          </template>
          <span v-else>{{ row.value }}</span>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
import { MessageBox, Message } from "element-ui";

export default {
  props: {
    propID: {
      type: Number
    },
    propData: {
      type: Array
    },
    propEditMode: {
      type: Boolean
    }
  },
  data() {
    return {
    };
  },
  created() {
  },
  methods: {
    handleChange() {
      this.$emit("CallbackRewadListChangeData", {
        id: this.propID,
        data: this.propData
      });
    }
  }
};
</script>