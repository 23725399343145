import request from '@/utils/request'

export function createUserCondition(userConditionData) {
    return request({
        url: '/userconditions',
        method: 'post',
        data: userConditionData
    })
}

export function getAllUserConditions() {
    return request({
        url: '/userconditions/all_user_conditions',
        method: 'get'
    })
}

export function updateUserCondition(userConditionID, userConditionData) {
    return request({
        url: '/userconditions/' + userConditionID,
        method: 'put',
        data: userConditionData
    })
}

export function deleteUserCondition(userConditionID) {
    return request({
        url: '/userconditions/' + userConditionID,
        method: 'delete'
    })
}