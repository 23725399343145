<template>
  <div class="list-user-filter">
    <el-transfer
      v-model="valueR"
      v-bind:data="datafilter"
      v-bind:titles="['Filters', 'Filter Apply']"
      v-loading="loading"
      v-on:change="handleChange"
    ></el-transfer>

    <el-card style="width:665px">
      <el-table :data="tableData">
        <el-table-column label="Name" width="200px">
          <template slot-scope="{row}">
            <span>{{ row.name }}</span>
          </template>
        </el-table-column>
        <el-table-column label="Value">
          <template slot-scope="{row}">
            <el-input placeholder="Please input value" v-model="row.value" @change="handleChange"></el-input>
          </template>
        </el-table-column>
      </el-table>
    </el-card>
  </div>
</template>

<script>
import { MessageBox, Message } from "element-ui";
import { Loading } from "element-ui";
import { getAllUserConditions } from "@/api/userconditions";

export default {
  props: {},
  data() {
    return {
      // data filter binded to left list
      datafilter: [],
      // values binded to right list
      valueR: [],
      // return result
      filterDataResult: [],
      //temporay select data
      dictionaryData: {},
      // UI Statused
      loading: false,
      // all user filter data loaded from database
      userConditionData: [],
      tableData: []
    };
  },
  created() {
    this.loadUserFilterData();
  },
  methods: {
    loadUserFilterData() {
      // update loading status
      this.loading = true;

      this.userConditionData = [];

      getAllUserConditions()
        .then(response => {
          response.forEach(i => {
            this.userConditionData.push({
              id: i.db_id,
              displayName: i.name,
              codeName: i.code,
              desc: i.description,
              editMode: false,
              updateMode: false,
              deleteMode: false,
              data: {
                name: i.name,
                code: i.code,
                description: i.description
              }
            });
          });

          this.datafilter = this.convertUserFilterDataToList(
            this.userConditionData
          );

          // update loading status
          this.loading = false;
        })
        .catch(error => {
          Message({
            message: "Can't fetch list User Filter: " + error,
            type: "error",
            duration: 5 * 1000
          });

          // update loading status
          this.loading = false;
        });
    },
    convertUserFilterDataToList(filterData) {
      const data1 = [];
      var keyVal = 0;

      for (let i = 0; i < filterData.length; i++) {
        keyVal = i + 1;

        data1.push({
          key: keyVal,
          label: filterData[i].displayName,
          disabled: false
        });
      }

      return data1;
    },
    handleChange() {
      // clear current data
      this.tableData = [];

      var dataItem;

      this.valueR.forEach(item => {
        if (this.dictionaryData[item] === undefined) {
          let itemFilter = this.datafilter.find(function(element) {
            return element.key === item;
          });

          this.userConditionData.forEach(itemUserConditionData => {
            if (itemUserConditionData.displayName === itemFilter.label) {
              dataItem = {
                name: itemFilter.label,
                code: itemUserConditionData.codeName,
                value: ""
              };

              this.dictionaryData[item] = dataItem;

              this.tableData.push(dataItem);
            }
          });
        } else {
          this.tableData.push(this.dictionaryData[item]);
        }
      });

      // generate reward string
      this.filterDataResult = [];
      this.tableData.forEach(itemTmp => {
        this.filterDataResult.push({
          name: itemTmp.name,
          code: itemTmp.code,
          value: itemTmp.value
        });
      });

      this.$emit("CallbackFillterData", this.filterDataResult);
    }
  }
};
</script>